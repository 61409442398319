import { Chip } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import React, { useEffect, useRef, useState } from 'react'
import ArrivalCertApiUrls from '../../../apiUrls/ArrivalCertApiUrls'
import ArrivalCertKeys from '../../../constants/locale/key/ArrivalCert'
import Namespace from '../../../constants/locale/Namespace'
import { getStatusMetadata } from '../../../common/NACommon'
import moment from 'moment-timezone'
import { FileForUserGetPartyId } from '../../../common/FileForUserCommon'
import pathMap from '../../../paths/pathMap'
import { generatePath } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AlertDialog from '../../../components/aciacehighway/AlertDialog'
import _ from 'lodash'
import Table from '../../../components/aciacehighway/Table'
import ArrivalCertUploadDialog from './ArrivalCertUploadDialog'

const {
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { BETWEEN, EQUAL, IN, LIKE },
  CodeMaintenanceType
} = constants

function TablePage(props) {
  function DisableSubmitButton(rows) {
    let shouldDisable = false
    rows.forEach((rowData) => {
      if (rowData.status === '1008') {
        shouldDisable = true
      }
    })
    return shouldDisable
  }

  function DisableSubmitAsModifyButton(rows) {
    let shouldDisable = false
    rows.forEach((rowData) => {
      if ((rowData.status !== "2" || rowData.errorDesc !== "EDIFACT conformance check error")) {
        shouldDisable = true
      }
    })
    return shouldDisable
  }

  const DisableDeleteButtonByStatus = (rowData) => {
    var isDisable = false
    for (let record in rowData) {
      var row = rowData[record]
      if (row.status !== "1005") {
        isDisable = true
        break
      }
    }
    return isDisable
  }

  const { history, showNotification } = props

  const [lookups, setLookups] = useState(null)
  const [alertDialog, setAlertDialog] = useState({ open: false, arrivalCerts: [] })
  const { translate } = useTranslation(Namespace.ARRIVAL_CERT)
  const translatedTextsObject = makeTranslatedTextsObject()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const tableRef = useRef()
  const { createRecord, deleteRecord } = useServices()
  const [uploadCSVDialog, setUploadCSVDialog] = useState(false)
  const { securedSendRequest } = useServices()

  const [statusLookup, setStatusLookup] = useState([]);
  useEffect(() => {
    Promise.all([fetchCodeMaintenanceLookup(constants.CodeMaintenanceType.CODE_MASTER,
      undefined, [{ field: 'codeType', operator: 'equal', value: 'ACIHWY_RNS_STATUS' }],
      undefined, 'code')])
      .then(([e]) => setStatusLookup(e))
  }, []);

  useEffect(() => {
    Promise.all([
      fetchCodeMaintenanceLookup(CodeMaintenanceType.CODE_MASTER, undefined, [
        { field: 'codeType', operator: EQUAL, value: 'ACI_HW_IBA_MSG_FUNC' }
      ])
    ]).then(([messageFunction]) => {
      setLookups({ messageFunction })
    })
  }, [])

  function makeTranslatedTextsObject() {
    let ccn = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.CCN
    )
    let destinationOfficeCode = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.DESTINATION_OFFICE_CODE
    )
    let messageFunction = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.MESSAGE_FUNCTION
    )
    let status = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.STATUS
    )
    let createdDate = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.CREATED_DATE
    )
    let cloneButton = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.CLONE_BUTTON
    )
    let deleteButton = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.DELETE_BUTTON
    )
    let deleteMultipleRecords = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.DELETE_MULTIPLE_RECORDS
    )
    let submitMultipleRecords = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.SUBMIT_MULTIPLE_RECORDS
    )
    let error = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.ERROR
    )
    let submitAsModify = translate(
      Namespace.ARRIVAL_CERT,
      ArrivalCertKeys.SUBMIT_AS_MODIFY
    )
    return {
      messageFunction,
      ccn,
      destinationOfficeCode,
      status,
      createdDate,
      cloneButton,
      deleteButton,
      deleteMultipleRecords,
      submitMultipleRecords,
      error,
      submitAsModify
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  const columns = [
    // { field: "id", title: "ID" },
    {
      field: "messageFunction",
      title: translatedTextsObject.messageFunction,
      render: (rowData) => getLookupValue('messageFunction', rowData.messageFunction)
    },
    {
      field: "ccn",
      title: translatedTextsObject.ccn,
    },
    {
      field: "destinationOfficeCode",
      title: translatedTextsObject.destinationOfficeCode,
    },
    {
      field: "status",
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        return (
          <Chip
            label={getStatusDescription(data.status)}
            size='small'
            style={{
              backgroundColor: status.color,
              color: status.contrastColor,
              fontSize: 12
            }}
          />
        )
      },
    },
    {
      field: "createdDate",
      title: translatedTextsObject.createdDate,
      render: (rowData) =>
        rowData.createdDate && moment(rowData.createdDate).tz("Canada/Eastern").format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      field: "errorDesc",
      title: translatedTextsObject.error,
    },
  ]

  const filters = [
    {
      label: translatedTextsObject.messageFunction,
      type: 'checkbox',
      name: 'messageFunction',
      operatorType: IN,
      options: [
        {
          label: 'Add',
          value: 'ADD',
          filterValue: {
            value: '9'
          }
        },
        {
          label: 'Cancel',
          value: 'CANCEL',
          filterValue: {
            value: '1'
          }
        },
        {
          label: 'Modify',
          value: 'MODIFY',
          filterValue: {
            value: '4'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.ccn,
      type: 'textfield',
      name: 'ccn',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.destinationOfficeCode,
      type: 'textfield',
      name: 'destinationOfficeCode',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          label: 'Draft',
          value: 'DRAFT',
          filterValue: {
            value: '1005'
          }
        },
        {
          label: 'Sent',
          value: 'SENT',
          filterValue: {
            value: '1008'
          }
        },
        {
          label: 'Goods released',
          value: 'GOODS_RELEASED',
          filterValue: {
            value: '4'
          }
        },
        {
          label: 'Declaration Accepted, Awaiting arrival of goods',
          value: 'DECLARATION_ACCEPTED_AWAITING_ARRIVAL_OF_GOODS',
          filterValue: {
            value: '9'
          }
        },
        {
          label: 'Declaration Accepted, Awaiting Customs Processing',
          value: 'DECLARATION_ACCEPTED_AWAITING_CUSTOMS_PROCESSING',
          filterValue: {
            value: '34'
          }
        },
        {
          label: 'Message Content Rejected with comment',
          value: 'MESSAGE_CONTENT_REJECTED_WITH_COMMENT',
          filterValue: {
            value: '2'
          }
        },
        {
          label: 'Message Content Accepted',
          value: 'MESSAGE_CONTENT_ACCEPTED',
          filterValue: {
            value: '1'
          }
        },
        {
          label: 'Goods required for examination - referred',
          value: 'GOODS_REQUIRED_FOR_EXAMINATION_REFERRED',
          filterValue: {
            value: '5'
          }
        },
        {
          label: 'Goods May Move, Detain at Destination (CFIA)',
          value: 'GOODS_MAY_MOVE_DETAIN_AT_DESTINATION_CFIA',
          filterValue: {
            value: '8'
          }
        },
        {
          label: 'Error message',
          value: 'ERROR_MESSAGE',
          filterValue: {
            value: '14'
          }
        },
        {
          label: 'Authorised to deliver CSA Shipment',
          value: 'AUTHORISED_TO_DELIVER_CSA_SHIPMENT',
          filterValue: {
            value: '23'
          }
        },
      ]
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.errorDesc,
      type: 'checkbox',
      name: 'errorDesc',
      operatorType: IN,
      options: [
        {
          label: 'EDIFACT conformance check error',
          value: 'EDIFACT conformance check error',
          filterValue: {
            value: 'EDIFACT conformance check error'
          }
        },
      ]
    }
  ]

  function getStatusDescription(statusCode) {
    if (statusLookup[statusCode] != null) {
      return statusLookup[statusCode];
    }
    return statusCode;
  }

  function handleCloneArrivalCert(rowData) {
    createRecord.execute(
      ArrivalCertApiUrls.CLONE,
      rowData,
      (res) =>
        history.push({
          pathname: pathMap.ACI_HIGHWAY_INBOND_ARRIVAL_ADD_VIEW,
          state: res
        }),
      (error) => {
        console.error(error)
        showNotification('error', 'Something went wrong when cloning invoice.')
      }
    )
  }

  function handleDeleteArrivalCerts() {
    if (!_.isEmpty(alertDialog.arrivalCerts)) {
      deleteRecord.execute(
        ArrivalCertApiUrls.DELETE_ALL,
        alertDialog.arrivalCerts,
        () => {
          showNotification('success', 'Delete succeeded')
          setAlertDialog({ open: false, arrivalCerts: [] })

          if (tableRef.current?.performRefresh) {
            tableRef.current.performRefresh()
          }
        },
        (error) => console.error(error),
        () => setAlertDialog({ open: false, arrivalCerts: [] })
      )
    }
  }

  async function submitAll(rows) {
    if (rows.length > 0) {
      tableRef.current.setLoading(true)
      securedSendRequest.execute('POST',
        ArrivalCertApiUrls.SUBMIT_ALL,
        rows,
        () => {
          showNotification('success', 'Submitted successfully')
          tableRef.current.performRefresh()
        },
        (error) => {
          showNotification('error', 'Something went wrong. Submit all failed.')
          console.log(error)
        },
        () => tableRef.current.setLoading(false)
      )
    }
  }

  async function submitAllAsModify(rows) {
    if (rows.length > 0) {
      tableRef.current.setLoading(true)
      const updatedRows = rows.map(row => ({
        ...row,
        messageFunction: "4" // Setting messageFunction to modify
      }));
      securedSendRequest.execute('POST',
        ArrivalCertApiUrls.SUBMIT_ALL,
        updatedRows,
        () => {
          showNotification('success', 'Submitted successfully')
          tableRef.current.performRefresh()
        },
        (error) => {
          showNotification('error', 'Something went wrong. Submit all failed.')
          console.log(error)
        },
        () => tableRef.current.setLoading(false)
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'upload']} />,
              onClick: () => setUploadCSVDialog({ open: true, tableRef: tableRef })
            },
            label: 'Upload inbond arrival'
          },
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () => history.push(pathMap.ACI_HIGHWAY_INBOND_ARRIVAL_ADD_VIEW)
            },
            label: 'Create inbond arrival'
          }
        ]}
        columns={columns}
        compact
        exportData={{ url: ArrivalCertApiUrls.EXPORT }}
        fetch={{ url: ArrivalCertApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) => {
          history.push(
            generatePath(
              rowData.status === '1008'
                ? pathMap.ACI_HIGHWAY_INBOND_ARRIVAL_DETAILS_VIEW
                : pathMap.ACI_HIGHWAY_INBOND_ARRIVAL_EDIT_VIEW,
              { id: rowData.id }
            )
          )
        }
        }
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
        checkboxSelection
        rowActions={[
          {
            disabled: (rowData) => rowData.status === '1008',
            tooltip: (rowData) => rowData.status === '1008' ? 'Unable to modify Sent status' : 'Edit',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: 'Edit',
            onClick: (rowData) =>
              history.push(
                generatePath(pathMap.ACI_HIGHWAY_INBOND_ARRIVAL_EDIT_VIEW, { id: rowData.id })
              )
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneArrivalCert
          }
        ]}
        selectActions={[
          {
            // disabled: (rows) => rows.length > 500,
            icon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
            label: "Submit All",
            onClick: (rows) => {
              return submitAll(rows);
            },
            disabled: (rows) => DisableSubmitButton(rows),
            tooltip: (rows) => DisableSubmitButton(rows) ? translatedTextsObject.submitMultipleRecords : null
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
            label: "Submit as modify",
            onClick: (rows) => {
              return submitAllAsModify(rows);
            },
            disabled: (rows) => DisableSubmitAsModifyButton(rows),
            tooltip: (rows) => DisableSubmitAsModifyButton(rows) ? translatedTextsObject.submitAsModify : null
          },
          {
            disabled: DisableDeleteButtonByStatus,
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: translatedTextsObject.deleteButton,
            onClick: (rows) => setAlertDialog({ open: true, arrivalCerts: rows }),
            tooltip: (rows) =>
              DisableDeleteButtonByStatus(rows)
                ? translatedTextsObject.deleteMultipleRecords
                : null
          }
        ]}
      />
      <ArrivalCertUploadDialog
        open={uploadCSVDialog}
        onClose={() => setUploadCSVDialog(false)}
        showNotification={showNotification}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={alertDialog.open}
        onClose={() => setAlertDialog({ open: false, arrivalCerts: [] })}
        onCancel={() => setAlertDialog({ open: false, arrivalCerts: [] })}
        onConfirm={handleDeleteArrivalCerts}
        title={translatedTextsObject.deleteButton}
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default TablePage
